import React, {Component, useCallback, useContext, useState} from 'react';
import Aux from "../Auxiliary/Auxiliary";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import classes from './Layouts.css';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import Footer from '../../components/Footer/Footer';
import Copyright from '../../components/Copyright/Copyright';
import Logo from '../../components/Logo/Logo';
import {Link, useHistory} from "react-router-dom";
import clsx from "clsx";
import {OfferDataContext} from "../../Provider/OfferData";
import {Steps, Divider, Row, Col} from 'antd';

const {Step} = Steps;

const Layouts = ({children}) => {
    const [showSideDrawer, setShowSideDrawer] = useState(false)
    const [stepsPosition, setStepsPosition] = useState('horizontal')
    let history = useHistory();
    const {isLoggedHook, setIsLoggedHook, currentStep} = useContext(OfferDataContext);
    console.log(isLoggedHook)
    const openSideDrawer = () => {
        setShowSideDrawer(true)
    }
    const closeSideDrawer = () => {
        setShowSideDrawer(false)
    }
    const logout = () => {
        const token = localStorage.getItem('token');
        if (token) {
            localStorage.removeItem('token')
            setIsLoggedHook(false)
        }
    }

    return (
        <>
            <Toolbar show={showSideDrawer} close={closeSideDrawer} open={openSideDrawer}/>
            <SideDrawer show={showSideDrawer} clicked={closeSideDrawer}/>
            <div className={classes.LogoMobile}>
                <Logo/>
            </div>
            <main className={classes.Container}>
                {isLoggedHook &&
                <div className={"text-center pb-5"}>
                    <button type={"button"} className={"btn btn-info ml-5"} onClick={() => history.push('/')}>Home
                    </button>
                    <button type={"button"} className={"btn btn-primary ml-5"}
                            onClick={() => history.push('/admin/oferte')}>Oferte
                    </button>
                    <button type={"button"} className={"btn btn-danger ml-5"} onClick={logout}>Logout
                    </button>
                </div>}
                {children}
                <Row style={{paddingBottom: '30px'}}>
                    <Col xs={1} span={8}>
                    </Col>
                    <Col xs={24} sm={() => {setStepsPosition('vertical')}}
                         md={() => {setStepsPosition('horizontal')}}
                         span={8}>
                        <Steps size={'small'} direction={stepsPosition} className={classes.Steps} current={currentStep}>
                            <Step  disabled title="Pasul 1" description=""/>
                            <Step  disabled title="Pasul 2" description=""/>
                        </Steps>
                    </Col>
                    <Col xs={1} span={8}>
                    </Col>
                </Row>
            </main>



            {/*<Footer/>*/}
        </>
    );

}

export default Layouts