import React,{useState} from "react";
import classes from './Button.css'

const button = (props) => {

    return (
        <button disabled={props.disabled}
                onClick={props.clicked}
                style={props.style}
                className={[classes.Button, classes[props.btnType]].join(' ')}>
            {props.children}
        </button>

    )
}
export default button