import React from "react";
import Logo from "../../Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import classes from './Toolbar.css';
import Menu from '../../Menu/Menu';
import { OfferDataContext } from "../../../Provider/OfferData";
import { OfferDataProvider } from "../../../Provider/OfferData";


const toolbar = (props) => {
    return (
        <div style={{maxWidth:'1400px',margin:'auto'}}>

        <header className={classes.Toolbar}>

             <div className={classes.Menu}>
                <Menu showMenu={props.show} closeMenu={props.close} openMenu={props.open}/>
            </div>
            <div className={classes.Logo}>
                <Logo/>
            </div>
            <div className={classes.DisplayOnlDesktop} >
                <div className={classes.Nav}>
                <OfferDataProvider>
                    <OfferDataContext.Consumer>
                        {value=>( <NavigationItems isLogged={value}/>)}
                    </OfferDataContext.Consumer>
                </OfferDataProvider>
                </div>
            </div>
        </header>
        </div>

    )
}
export default toolbar;