import React, {createContext, useState} from 'react';

export const OfferDataContext = createContext({});
export const OfferDataProvider = (props) => {
    const selectedValuesHook = useState([]);
    const selectedValuesHookObj = useState({});
    const recaptchaHook = useState(false);
    const selectedAgentNoHook = useState(null);
    const selectedHoursNoHook = useState(null);
    const selectedInterventionsNoHook = useState(null);
    const carpetCleaningHook = useState(null);
    const constructCleaningHook = useState(null);
    const officeSurfaceHook = useState(null);
    const otherServiceHook = useState(null);
    const [isLoggedHook, setIsLoggedHook] = useState(false);

    //second form
    const [formState, setFormState] = useState({
        lastName: {value: '', valid: false},
        firstName: {value: '', valid: false},
        phone: {value: '', valid: false},
        email: {value: '', valid: false},
        company: {value: '', valid: false},
        city: {value: '', valid: false},
    });
    const [validForm, setValidForm] = useState(false);
    const [currentStep, setCurrentStep] = useState(0)

    const value = {
        selectedValuesHook,
        selectedValuesHookObj,
        selectedAgentNoHook,
        selectedHoursNoHook,
        selectedInterventionsNoHook,
        carpetCleaningHook,
        constructCleaningHook,
        officeSurfaceHook,
        otherServiceHook,
        recaptchaHook,
        isLoggedHook,
        setIsLoggedHook,
        formState,
        setFormState,
        validForm,
        setValidForm,
        currentStep,
        setCurrentStep
    }
    return (
        <OfferDataContext.Provider value={value}>
            {props.children}
        </OfferDataContext.Provider>
    )
} 