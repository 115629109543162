import React,{} from 'react';
import {Link, useLocation} from "react-router-dom";

export const PageNotFound = () => {
    const path  = useLocation();

    let display = path.pathname === '/admin/oferte' ? 'none' : 'block'
    return (
        <div className={'PageNotFound'} style={{margin: '200px auto', textAlign: 'center',display:display}}>
            <h1>404</h1>
            <h3>Pagina nu există !</h3>
            <Link to="/">
                Inapoi la prima pagină
            </Link>
        </div>
    )
}