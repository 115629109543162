import React from "react";
import forbecLogo from '../../assets/images/forbec-logo.jpg';
import classes from './Logo.css';
import {Link} from "react-router-dom";

const logo = () => {
    return (
        <a href={"https://www.forbec.ro"}>
            <img src={forbecLogo} alt='Forbec Logo'/>
        </a>
    )
}
export default logo;