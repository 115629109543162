import React, { useState, useContext,useEffect} from 'react';
import Input from "../../components/UI/Input/Input";
import classes from './../ContactData/ServicesData/ServicesData.css';
import { OfferDataContext } from '../../Provider/OfferData';


export const CarpetCleaning = (props) => {
    const { carpetCleaningHook: [carpetCleaning, setCarpetCleaning] } = useContext(OfferDataContext);
    // let error = null;
    const [error,setError] = useState(null);
    const handleChange = (event) => {
        setCarpetCleaning(event.target.value)
    };
    useEffect(()=>{
       if(!carpetCleaning || carpetCleaning === null){
        const val = <p style={{fontSize:'10px',color:'darkred'}}>Acest câmp este obligatoriu</p>
        setError(val)
       }else{
        setError(null)
    }
    },[carpetCleaning])

    return <>
        <div className={classes.OfficeService}>
            <p className={classes.Paragraph}>Suprafață mochetă(m2)<span style={{ color: 'darkred' }}> *</span></p>

            <Input
                elementConfig={{ type: 'number' }}
                type={'input'}
                label={''}
                changed={handleChange}
                value={carpetCleaning}
            />
            <div className={classes.ErrorMessage} style={{marginTop:'-20px'}}>
                {error}
            </div>
            <div className={classes.Instruction}>
                <p>Introduceți un număr cuprins între 1 și 1000.</p>
            </div>
        </div>
    </>
}
