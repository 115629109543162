import React from 'react';
import classes from './NavigationItems.css';

const navigationItems = () => {
    return (
        <>
            <a className={classes.Link} style={{fontSize: '13px'}} href='https://forbec.ro'>ACASĂ</a>
            <a className={classes.Link} style={{fontSize: '13px'}} href='https://forbec.ro/despre-noi'>DESPRE NOI</a>
            <a className={[classes.dropdown, classes.Link].join(' ')} style={{fontSize: '13px'}}>SERVICII
                <span className={classes.dropdownContent}>
                    <a href="https://forbec.ro/servicii/curatenia-de-intretinere-birouri">CURĂȚENIE BIROURI</a>
                    <a href="https://forbec.ro/servicii/curatenie-generala">CURĂȚENIE GENERALĂ</a>
                    <a href="https://forbec.ro/servicii/curatenie-dupa-constructor">CURĂȚENIE DUPĂ CONSTRUCTOR</a>
                    <a href="https://forbec.ro/servicii/curatenia-industriala">CURĂȚENIE INDUSTRIALĂ</a>
                    <a href="https://forbec.ro/servicii/alpinismul-utilitar">ALPINISM UTILITAR</a>
                    <a href="https://forbec.ro/servicii/curatare-pardoseli">SPĂLARE MOCHETĂ</a>
                </span>
            </a>
            <a className={classes.Link} style={{fontSize: '13px'}} href='https://forbec.ro/cariera'>CARIERĂ</a>
            <a className={classes.Link} style={{fontSize: '13px'}} href='https://forbec.ro/faqs'>FAQS</a>
            <a className={classes.Link} style={{fontSize: '13px'}} href='https://forbec.ro/contact'>CONTACT</a>
        </>
    )
};
export default navigationItems;