import React, {Component, useState} from "react";
import classes from './ContactData.css'
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import Aux from "../../hoc/Auxiliary/Auxiliary";
import {Review, ServiceForm} from './ServiceForm/ServiceForm';
import {OfferDataContext} from '../../Provider/OfferData'
import {SaveData} from '../SaveData/SaveData';
import {Rate} from 'antd';
import {Link} from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

class ContactData extends Component {

    static contextType = OfferDataContext;

    state = {
        formControls: {
            lastName: {
                elementType: 'input',
                label: 'Prenume',
                elementConfig: {
                    type: 'text',
                },
                validation: {
                    required: {
                        state: true,
                        message: 'Acest câmp este obligatoriu.'
                    },
                },
                multipleValidation: false,
                valid: this.context.formState.lastName.valid,
                value: this.context.formState.lastName.value,
                touched: false
            },
            firstName: {
                elementType: 'input',
                label: 'Nume',
                elementConfig: {
                    type: 'text',
                },
                validation: {
                    required: {
                        state: true,
                        message: 'Acest câmp este obligatoriu.'
                    },
                },
                multipleValidation: false,
                valid: this.context.formState.firstName.valid,
                value: this.context.formState.firstName.value,
                touched: false
            },
            phone: {
                elementType: 'input',
                label: 'Telefon',
                elementConfig: {
                    type: 'text',
                },
                validation: {
                    required: {
                        state: true,
                        message: 'Acest câmp este obligatoriu. Format : 07********, 10 cifre!'
                    },
                    isPhone: {
                        state: true,
                        // message : 'Format : 07**, 10 cifre!'
                    },
                    phoneMaxNo: {
                        state: true
                    }
                },
                multipleValidation: false,
                valid: this.context.formState.phone.valid,
                value: this.context.formState.phone.value,
                touched: false
            },
            email: {
                elementType: 'input',
                label: 'Email',
                elementConfig: {
                    type: 'email',
                },
                validation: {
                    required: {
                        state: true,
                        message: 'Acest câmp este obligatoriu.'
                    },
                    isEmail: {
                        state: true,
                        message: 'Vă rugăm să introduceți o adresă de e-mail validă.'
                    },
                },
                multipleValidation: true,
                valid: this.context.formState.email.valid,
                value: this.context.formState.email.value,
                touched: false
            },
            company: {
                elementType: 'input',
                label: 'Societate Comercială',
                elementConfig: {
                    type: 'text',
                },
                validation: {
                    required: {
                        state: true,
                        message: 'Acest câmp este obligatoriu.'
                    },
                },
                multipleValidation: false,
                valid: this.context.formState.company.valid,
                value: this.context.formState.company.value,
                touched: false
            },
            // cui: {
            //     elementType: 'input',
            //     label: 'Cui',
            //     elementConfig: {
            //         type: 'text',
            //     },
            //     validation: {
            //         required: {
            //             state: false,
            //             message: 'Acest câmp este obligatoriu.'
            //         },
            //     },
            //     multipleValidation: false,
            //     valid: false,
            //     value: '',
            //     touched: false
            // },
            city: {
                elementType: 'input',
                label: 'Oraș',
                elementConfig: {
                    type: 'text',
                },
                validation: {
                    required: {
                        state: true,
                        message: 'Acest câmp este obligatoriu.'
                    },
                },
                multipleValidation: false,
                valid: this.context.formState.city.valid,
                value: this.context.formState.city.value,
                touched: false
            },
            // region: {
            //     elementType: 'input',
            //     label: 'Județ',
            //     elementConfig: {
            //         type: 'text',
            //     },
            //     validation: {
            //         required: {
            //             state: true,
            //             message: 'Acest câmp este obligatoriu.'
            //         },
            //     },
            //     multipleValidation: false,
            //     valid: false,
            //     value: '',
            //     touched: false
            // }
        },
        loading: false,
        visible: true,
        isValidForm: this.context.validForm,
        isInvalidButton: false,
        errorCheck: false,
        formCompleted: false, //false
        neededService: false,
        personsNumber: false,
        hourNumber: false,
        weekIntervention: false,
        officeSurface: false,
        carpetSurface: false,
        serviceFormVisibility: false,
        sendRequestStatus: false,
        spinner: false,
        sweetAlert: true,
        warningSweetAlert: true,
    };

    componentDidMount() {
        this.context.setCurrentStep(1)
    }

    checkInputValidity = (value, rules) => {
        let isValid = true;

        if (rules.isEmail) {
            let regexEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
            isValid = regexEmail.test(value) && isValid
        }
        if (rules.required.state) {
            isValid = value.trim() !== '' && isValid
        }
        if (rules.isPhone) {
            isValid = value.substring(0, 2) == '07' && isValid
        }
        if (rules.phoneMaxNo) {
            isValid = value.length == 10 && isValid
        }
        return isValid
    };
    changeInputHandler = (input, event) => {
        const updateForm = {
            ...this.state.formControls
        }
        const selectedInput = {
            ...updateForm[input]
        };

        selectedInput.valid = this.checkInputValidity(event.target.value, selectedInput.validation)
        selectedInput.value = event.target.value;
        updateForm[input] = selectedInput;
        const oldContext = {
            ...this.context.formState
        }
        oldContext[input]['value'] = selectedInput.value;
        oldContext[input]['valid'] = selectedInput.valid;
        this.context.setFormState({
            ...this.context.formState,
            oldContext
        });

        let isValidForm = true;
        for (let formInput in updateForm) {
            isValidForm = updateForm[formInput].valid && isValidForm
        }
        this.context.setValidForm({isValidForm});
        this.setState({
            ...this.state,
            formControls: updateForm,
            isValidForm: isValidForm,
            isInvalidButton: !isValidForm
        })
    };

    formHandler = (event) => {
        event.preventDefault();
        if (this.state.isValidForm) {
            this.setState({
                ...this.state,
                sendRequestStatus: true,
                spinner: true
            })
            const data = {
                contactData: this.state,
                serviceData: this.context
            }
            axios.post(`${process.env.REACT_APP_SERVER_URL}/api/cere-oferta`, data)
                .then(res => {
                    this.setState({
                        ...this.state,
                        spinner: false,
                        sendRequestStatus: false
                    });
                    if (this.state.sweetAlert) {
                        swal("Îți mulțumim!", "Veți primi oferta în câteva momente.", "success");
                    }
                    // setTimeout(() => {
                    //     this.setState({...this.state, sweetAlert: false})
                    //     window.location.href = "https://www.forbec.ro/va-multumim";
                    // }, 2000)
                })
                .catch(err => {
                    this.setState({
                        ...this.state, spinner: false, sendRequestStatus: false
                    })
                    // setIsValid(false);
                    if (this.state.sweetAlert) {
                        swal("A intervenit o eroare!", "Încercați din nou.", "warning");
                    }
                    setTimeout(() => {
                        this.setState({...this.state, sweetAlert: false})
                        window.location.reload(false);
                    }, 2000)
                })
        } else {
            this.setState({
                ...this.state,
                loading: true,
                errorCheck: true,
                formCompleted: this.state.isValidForm
            });
        }
    };

    render() {

        const style = {
            marginLeft: '50px', marginTop: '-20px'
        }
        const style2 = {
            position: 'absolute'
        }
        let formInputArray = [];
        for (let input in this.state.formControls) {
            formInputArray.push({
                id: input,
                config: this.state.formControls[input]
            })
        }
        // let spinner = this.state.spinner ? <Spinner/> : null;

        return (
            <div className={classes.Main}>
                <div className={classes.ContactData}>
                    {/*{!this.state.formCompleted ?*/}
                    <>
                        <p className={classes.ContactDataParagraph}>Detalii contact</p>
                        <hr style={{border: '1px thin red', width: '100%'}}/>
                        <form className={classes.ContactDataForm} onSubmit={() => false}>
                            {formInputArray.map(input => {

                                return <Input key={input.id}
                                              id={input.id + '_' + Math.random().toString(36).substring(7)}
                                              value={input.config.value}
                                              elementConfig={input.config.elementConfig}
                                              type={input.config.elementType}
                                              label={input.config.label}
                                              multipleValidation={input.config.multipleValidation}
                                              touched={input.config.touched}
                                              name={input.id}
                                              invalid={!input.config.valid}
                                              errors={input.config.validation}
                                              errorCheck={this.state.errorCheck}
                                              changed={(event) => this.changeInputHandler(input.id, event)}
                                />
                            })}
                            <div className={classes.NextStep} style={{display: 'inline-block', paddingTop: '30px'}}>
                                <Button clicked={this.props.previousStep}
                                        disabled={false}
                                        btnType={'Continue'}>PASUL ANTERIOR
                                </Button>
                                <Button clicked={this.formHandler}
                                        disabled={this.state.sendRequestStatus}
                                        btnType={'Continue'}>CERE OFERTA <span style={style2}><div
                                    style={style}>{this.state.spinner && <Spinner/>}</div></span>

                                </Button>
                                {/*<div style={{marginLeft: '200px', marginTop: '-40px'}}>{spinner}</div>*/}
                            </div>
                        </form>
                    </>
                    {/*// :*/}
                    {/*// <form className={classes.PreviousStep}>*/}
                    {/*//     <ServiceForm previousStep={this.state.formCompleted}/>*/}
                    {/*//     <Button btnType='Continue' clicked={this.previousStep} disabled={false}>PASUL*/}
                    {/*//         ANTERIOR</Button>*/}
                    {/*//     <OfferDataContext.Consumer>*/}
                    {/*//         {value => (<SaveData formData={this.state} formData2={value}/>)}*/}
                    {/*//     </OfferDataContext.Consumer>*/}
                    {/*// </form>*/}
                    {/*}*/}
                </div>
                <Review contactData={true}/>
            </div>
        )
    }
}

export default ContactData
