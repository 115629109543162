import React, {useState, useContext, useEffect} from 'react';
import Input from "../../../components/UI/Input/Input";
import {PersonsNumber} from '../../ServiceFormSelected/PersonsNumber';
import {GeneralCleaning} from '../../ServiceFormSelected/GeneralCleaning';
import {CarpetCleaning} from '../../ServiceFormSelected/CarpetCleaning';
import {OtherService} from '../../ServiceFormSelected/OtherService';
import {ConstructionCleaning} from '../../ServiceFormSelected/ConstructionCleaning';
import {OfferDataContext} from '../../../Provider/OfferData';
import classes from '../ServicesData/ServicesData.css';
import Recaptcha from 'react-recaptcha';
import Button from "../../../components/UI/Button/Button";
import ContactData from "../ContactData";
import {Row, Col, Rate} from 'antd';
import css from './../ContactData.css'

const values = {
    'Curățenie de întreținere/Birouri': PersonsNumber,
    'Curățenie după constructor': ConstructionCleaning,
    'Curățenie generală': GeneralCleaning,
    'Spălare mochetă': CarpetCleaning,
    'Altul': OtherService,
}


export function Review({contactData}) {
    let style = contactData ? css.Review : classes.Review
    return (
        <div className={style}>
            <p className={css.ContactDataParagraph}>Recenzii</p>
            <hr style={{border: '1px thin red'}}/>
            <div>
                <div>
                    {/*review 1*/}
                    <div className='pt-2 '>
                        <div>
                            <div>
                                <div>
                                    <Rate style={{color: '#f5a623'}} disabled defaultValue={5}/>
                                </div>
                            </div>
                            <div>
                                <p className="font-italic">”Recomandăm serviciile de curățenie ale celor de la
                                    Forbec Services tuturor persoanelor și companiilor care doresc să își
                                    desfășoare activitățile de la serviciu într-un mediu de lucru perfect
                                    curat. Am descoperit niște oameni profesioniști și foarte amabili. Nu mai
                                    spun de disponibilitatea de care au dat dovadă de fiecare dată când a fost
                                    nevoie.”</p>
                            </div>
                            <div className='mt-n2'>
                                <span className='font-weight-bold'>Nikita</span> - <span><a
                                href={'https://fivetn.com'}>Fivetn Development</a></span>
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">
                        <div>
                            <div>
                                <div>
                                    <Rate style={{color: '#f5a623'}} disabled defaultValue={5}/>
                                </div>
                            </div>
                            <div>
                                <p className="font-italic">”Avem o colaborare de peste 7 ani cu echipa Forbec.
                                    Tot timpul au dat dovadă de flexibilitate, calitate și servicii comerciale
                                    de excepție. Recomandăm cu încredere!”</p>
                            </div>
                            <div className='mt-n2 '><span className='font-weight-bold'>Ionuț</span> - <span><a
                                href={'https://www.pretzmic.ro/'}>Evoprom</a></span>

                            </div>

                        </div>
                    </div>
                    {/*review 2*/}
                    <div className='pt-4 '>
                        <div>
                            <div>
                                <div>
                                    <Rate disabled style={{color: '#f5a623'}} allowHalf={true}
                                          defaultValue={5}/>
                                </div>
                            </div>
                            <div>
                                <p className="font-italic">”Super profesioniști!”</p>
                            </div>
                            <div className='mt-n2'>
                                <span className='font-weight-bold'>Florin Moldovan</span> -<span><a
                                href={'https://www.magphone.ro/'}> Magphone</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export const ServiceForm = (props) => {
    let positionArr = [];
    const {selectedValuesHook: [selectedValues, setSelectedValues]} = useContext(OfferDataContext);
    const {recaptchaHook: [recaptcha, setRecaptcha]} = useContext(OfferDataContext);
    const {isLoggedHook, setIsLoggedHook, currentStep, setCurrentStep} = useContext(OfferDataContext);
    const {selectedAgentNoHook: [selectedAgentNo, setSelectedAgentNo]} = useContext(OfferDataContext);
    const {selectedHoursNoHook: [selectedHoursNo, setSelectedHoursNo]} = useContext(OfferDataContext);
    const {selectedInterventionsNoHook: [selectedInterventionsNo, setSelectedInterventionsNo]} = useContext(OfferDataContext);
    const {carpetCleaningHook: [carpetCleaning, setCarpetCleaning]} = useContext(OfferDataContext);
    const {officeSurfaceHook: [officeSurface, setOfficeSurface]} = useContext(OfferDataContext);
    const {constructCleaningHook: [constructCleaning, setConstructCleaning]} = useContext(OfferDataContext);
    const {otherServiceHook: [otherService, setOtherService]} = useContext(OfferDataContext);
    const [serviceCompleted, setServiceCompleted] = useState(false)
    const [isValid, setIsValid] = useState(true);
    const {formData, formData2} = props;


    const isCheckHandler = (service) => {
        return selectedValues.filter(item => item === service).length > 0
    }
    const validityHandler = () => {
        let isValid = true;

        if (selectedValues.length < 1) {
            isValid = false;
        }

        if (isCheckHandler('Curățenie de întreținere/Birouri')) {
            isValid = false;
            if (selectedAgentNo == 0 || selectedHoursNo == 0 || selectedInterventionsNo !== null) {
                isValid = true
            }
        }
        if (isCheckHandler('Curățenie după constructor')) {
            isValid = constructCleaning !== null && isValid
            if (constructCleaning !== null) {
                isValid = constructCleaning !== "" && isValid
            }
        }
        if (isCheckHandler('Curățenie generală')) {
            isValid = officeSurface !== null && isValid
            if (officeSurface !== null) {
                isValid = officeSurface !== "" && isValid
            }
        }
        if (isCheckHandler('Spălare mochetă')) {
            isValid = carpetCleaning !== null && isValid
            if (carpetCleaning !== null) {
                isValid = carpetCleaning !== "" && isValid
            }

        }
        if (isCheckHandler('Altul')) {
            isValid = otherService !== null && isValid
            if (otherService !== null) {
                isValid = otherService !== "" && isValid
            }
        }

        // if (recaptcha[0] && selectedValues[0].length > 0) {
        //     isValid = recaptcha && isValid
        // } else {
        //     isValid = recaptcha[0] && isValid && false
        // }

        return !isValid;
    }

    useEffect(() => {
        setIsValid(validityHandler());
        return () => {
            setIsValid(true);
        }
    }, [selectedValues, selectedAgentNo, selectedHoursNo, selectedInterventionsNo,constructCleaning, carpetCleaning, officeSurface, otherService])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [serviceCompleted])

    // useEffect(() => {
    //     console.log(12)
    //     setCurrentStep(0)
    //     return () => {
    //        // set recaptha false
    //     }
    // }, [setCurrentStep])
    let index = null;
    const handleChange = (value, event) => {
        const {checked} = event.target;
        switch (value) {
            case 'Curățenie de întreținere/Birouri':
                index = 0;
                break;
            case 'Curățenie după constructor':
                index = 1;
                break;
            case 'Curățenie generală':
                index = 2;
                break;
            case 'Spălare mochetă':
                index = 3;
                break;
            case 'Altul':
                index = 4;
                break;
        }
        if (checked) {
            const array = [...selectedValues];
            array.splice(index, 0, value);
            setSelectedValues(array);
        } else {
            setSelectedValues(selectedValues.filter((item) => item !== value));
            handleContextClean(value);
        }
    };
    const selectedPanels = selectedValues.map((value) => {
        const Component = values[value];
        return <Component key={value} previous={props.previousStep}/>
    });


    const recaptchaLoaded = () => {
        console.log('recacaptha loadead succesfully!')
    }
    const verifyCallback = (response) => {
        if (response) {
            setRecaptcha(true);
        }
    }

    const expiredCallback = () => {
        setRecaptcha(false);
    }

    const handleContextClean = (value) => {
        switch (value) {
            case 'Curățenie de întreținere/Birouri':
                setSelectedAgentNo(null);
                setSelectedHoursNo(null);
                setSelectedInterventionsNo(null)
                break;
            case 'Curățenie după constructor':
                setConstructCleaning(null)
                break;
            case 'Curățenie generală':
                setOfficeSurface(null)
                break;
            case 'Spălare mochetă':
                setCarpetCleaning(null);
                break;
            case 'Altul':
                setOtherService(null)
                break;
        }
    }
    const serviceCompletedHandler = () => {
        setServiceCompleted(true)
        setCurrentStep(currentStep + 1)
    }
    const previousStep = () => {
        setServiceCompleted(false)
        setCurrentStep(currentStep - 1)
    }
    return (

        <div>
            {!serviceCompleted ?
                <div className={classes.ServiceForm}>
                    <Row style={{paddingBottom: '70px'}}>
                        <Col xs={24} md={12} span={12}>
                            <p className={classes.ServiceCaptcha}>Serviciu dorit<span
                                style={{color: 'darkred'}}> *</span>
                            </p>
                            {Object.keys(values).map((value) =>
                                <Input
                                    key={value}
                                    label={value}
                                    type='input'
                                    value={selectedValues.find(item => item === value)}
                                    elementConfig={{type: 'checkbox'}}
                                    changed={(event) => handleChange(value, event)}
                                />
                            )}
                            {selectedPanels}
                            <div className={classes.NextStep}
                                 style={{display: 'inline-block', paddingTop: '30px', paddingBottom: '20px'}}>
                                <Button clicked={serviceCompletedHandler}
                                        disabled={isValid}
                                        btnType={'Continue'}>PASUL URMĂTOR
                                </Button>
                            </div>
                        </Col>

                        <Col xs={24} md={12} span={12}>
                            <Review contactData={false}/>
                        </Col>
                    </Row>
                </div> :
                <ContactData previousStep={previousStep}/>}
        </div>


    )
}
{/*RECAPTCHA*/
}
{/*<div style={{ margin: '30px 0' }}>*/
}
{/*  <p className={classes.ServiceCaptcha}>Verificare CAPTCHA</p>*/
}
{/*  /!*<Recaptcha*!/*/
}
{/*  /!*  sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}*!/*/
}
{/*  /!*  render="explicit"*!/*/
}
{/*  /!*  onloadCallback={recaptchaLoaded}*!/*/
}
{/*  /!*  verifyCallback={verifyCallback}*!/*/
}
{/*  /!*  expiredCallback={expiredCallback}*!/*/
}
{/*  /!*  hl={"ro"} />*!/*/
}
{/*</div>*/
}