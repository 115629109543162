import React, { Component } from "react";
import inputClasses from './Input.css'
import checkboxClasses from './Checkbox.css';
import radioClasses from './Radio.css';

class Input extends Component {

    state = {
        displayErrors: false,
        label: Math.random().toString(36).substring(7),
        selectedRadio: ''
    };

    componentDidMount() {
        this.setState({
            displayErrors: true,
        })
    }
    render() {
        let errorMessage = '';
        if (this.props.invalid && this.props.multipleValidation) {
            errorMessage = <p style={{ fontSize: '13px', color: 'darkred' }}>{this.props.errors.isEmail.message}</p>
        }
        if (this.props.invalid && !this.props.multipleValidation) {

            errorMessage =
                <p style={{ fontSize: '13px', color: 'darkred' }}>{this.props.errors.required.message}</p>
        }
        let input = null;
        let cssClassAdded = null;
        switch (this.props.type) {
            case 'input':

                if (this.props.elementConfig.type === 'text' || this.props.elementConfig.type === 'email' || this.props.elementConfig.type === 'number') {
                    input = <input id={this.state.label} name={this.props.name}  aria-required={true} aria-invalid={false} onChange={this.props.changed}  {...this.props.elementConfig}
                        value={this.props.value} />;
                    cssClassAdded = inputClasses.Input;
                }
                if (this.props.elementConfig.type === 'checkbox') {
                    input = <input id={this.state.label} onChange={this.props.changed}  {...this.props.elementConfig}
                        checked={this.props.value} defaultChecked={false} />;
                    cssClassAdded = checkboxClasses.Checkbox;
                }
                if (this.props.elementConfig.type === 'radio') {
                    input = <input id={this.state.label} name={this.props.name} onChange={this.props.changed}
                        {...this.props.elementConfig}
                        checked={this.props.value} />;
                    cssClassAdded = radioClasses.Radio;
                }
                break;
            default:
                return input;
        }
        return (

            <div className={cssClassAdded}>
                <ul>
                    <li>
                        <div>
                            <label htmlFor={this.state.label}>{this.props.label}
                                {this.props.elementConfig.type === 'text' || this.props.elementConfig.type === 'email' ?
                                    <span> *</span> : null}
                            </label>
                            {input}
                        </div>
                        {(this.state.displayErrors && this.props.errorCheck) ? errorMessage : ''}
                    </li>
                </ul>
            </div>
        )
    }
}
export default Input