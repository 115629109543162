import React from 'react';
import NavigationItems from "../Navigation/NavigationItems/NavigationItems";
import classes from './SideDrawer.css';

const sideDrawer = (props) => {
    let attachedClasses = [classes.MenuBurger,classes.SideDrawer, classes.Close];
    if (props.show) {
        attachedClasses = [classes.MenuBurger,classes.SideDrawer, classes.Open]
    }
    return (
        <>
            <div className={attachedClasses.join(' ')} onClick={props.clicked}>
                <NavigationItems/>
            </div>
        </>
    );
};

export default sideDrawer;