// import classes from '*.module.css';
import React, { useState, useContext, useEffect } from 'react';
import Input from "../../components/UI/Input/Input";
import { OfferDataContext } from '../../Provider/OfferData';
import classes from '../ContactData/ServicesData/ServicesData.css';
const data = { // nr persoana  ->  nr ore   ->    nr interventii
    1: {
        2: [1, 2, 3, 5],
        3: [1, 2, 3, 5],
        4: [5],
        5: [5],
        6: [5],
        8: [5],
    },
    2: {
        1: [1, 2, 3],
        2: [1, 2, 3, 5],
        3: [5],
        4: [5],
        8: [5],
    },
    3: {
        8: [5]
    },
    4: {
        8: [5]
    }
}

export const PersonsNumber = (props) => {
    const { selectedAgentNoHook: [selectedAgentNo, setSelectedAgentNo] } = useContext(OfferDataContext);
    const { selectedHoursNoHook: [selectedHoursNo, setSelectedHoursNo] } = useContext(OfferDataContext);
    const {selectedInterventionsNoHook: [selectedInterventionsNo, setSelectedInterventionsNo]} = useContext(OfferDataContext);
    const [error,setError] = useState(null);
    const personNumbers = Object.keys(data);
    const handleAgentNo = (agent) => {
        setSelectedAgentNo(agent);
        setSelectedHoursNo(null);
        setSelectedInterventionsNo(null)
    }
    useEffect(()=>{
        if(selectedAgentNo === null){
         const val = <p style={{fontSize:'10px',color:'darkred'}}>Acest câmp este obligatoriu</p>
         setError(val)
        }else{
         setError(null)
     }
     },[selectedAgentNo])
    return (
        <div className={classes.OfficeGeneralService}>
            <p>Număr persoane {selectedAgentNo}<span style={{ color: 'darkred' }}> *</span></p>
            {personNumbers.map((agentNo) => <Input
                key={agentNo}
                label={`${agentNo} ${agentNo == 1 ? 'agent' :'agenți'}  curățenie`}
                type='input'
                elementConfig={{ type: 'radio' }}
                name='agenticuratenie'
                value={selectedAgentNo === agentNo}
                changed={(event) => {
                    handleAgentNo(agentNo)
                }
                }
            />)}
            <Input
                label={`Nu știu/Nici unul dintre cele menționate`}
                type='input'
                name='agenticuratenie'
                value={selectedAgentNo === 0}
                elementConfig={{ type: 'radio' }}
                changed={(event) => handleAgentNo(0)}
            />
            <div className={classes.ErrorMessage}>
                {error}
            </div>
            {!!selectedAgentNo && <HoursNmber agentNo={selectedAgentNo} />}
        </div>
    )
}

const HoursNmber = (props) => {

    const { selectedHoursNoHook: [selectedHoursNo, setSelectedHoursNo] } = useContext(OfferDataContext);
    const offeData = useContext(OfferDataContext);
    const [selectedInterventionsNo, setSelectedInterventionsNo] = offeData.selectedInterventionsNoHook
    const hourNo = Object.keys(data[props.agentNo]);
    const [currentAgentNo, setCurrentAgentNo] = useState(null);
    const [error,setError] = useState(null);

    const { agentNo } = props;
    const handleHoursNo = (hour) => {
        setSelectedHoursNo(hour);
        setSelectedInterventionsNo(null);
    }
    useEffect(()=>{
        if(selectedHoursNo === null){
         const val = <p style={{fontSize:'10px',color:'darkred'}}>Acest câmp este obligatoriu</p>
         setError(val)
        }else{
         setError(null)
     }
     },[selectedHoursNo])
    return (
        <div className={classes.OfficeGeneralService}>
            <p>Număr Ore ({agentNo}) {agentNo == 1 ? 'agent':'agenți' }<span style={{ color: 'darkred' }}> *</span></p>
            {hourNo.map((hourNo) => <Input
                key={hourNo}
                label={`${hourNo} ${hourNo == 1 ? 'oră' :'ore'}`}
                type='input'
                value={selectedHoursNo === hourNo}
                elementConfig={{ type: 'radio' }}
                name='hours'
                changed={(event) => handleHoursNo(hourNo)}
            />)}
            <Input
                label={`Nu știu/Nici unul dintre cele menționate`}
                type='input'
                name='hours'
                value={selectedHoursNo === 0}

                elementConfig={{ type: 'radio' }}
                changed={(event) => handleHoursNo(0)}
            />
              <div className={classes.ErrorMessage}>
                {error}
            </div>

            {!!selectedHoursNo && props.agentNo !== null && <InterventionsNumber agentNo={props.agentNo} hoursNo={selectedHoursNo} />}
        </div>
    )
}

const InterventionsNumber = (props) => {
    const offeData = useContext(OfferDataContext);
    const [selectedInterventionsNo, setSelectedInterventionsNo] = offeData.selectedInterventionsNoHook
    const interventionsNo = data[props.agentNo][props.hoursNo];
    const [isRender, setIsRender] = useState(true);
    const { agentNo, hoursNo } = props;
    const [error,setError] = useState(null);
    useEffect(() => {
        setIsRender(false);
    }, [agentNo])

    useEffect(() => {
        setIsRender(true)
    }, [hoursNo])

    useEffect(()=>{
        if(selectedInterventionsNo === null){
         const val = <p style={{fontSize:'10px',color:'darkred'}}>Acest câmp este obligatoriu</p>
         setError(val)
        }else{
         setError(null)
     }
     },[selectedInterventionsNo])
    const renderContent = !!interventionsNo && isRender && (
        <>
            <p>Număr Interventii <span style={{ color: 'darkred' }}> *</span></p>
            {interventionsNo.map((interventionNo) => <Input
                key={interventionNo}
                label={`${interventionNo} ${interventionNo == 1 ? 'intervenție/săptămână' :'intervenții/săptămână'}`} 
                type='input'
                value={selectedInterventionsNo === interventionNo}
                elementConfig={{ type: 'radio' }}
                name='interventions'
                changed={(event) => setSelectedInterventionsNo(interventionNo)}
            />)}
            <Input
                label={`Nu știu/Nici unul dintre cele menționate`}
                type='input'
                value={selectedInterventionsNo === 0}
                name='interventions'
                elementConfig={{ type: 'radio' }}
                changed={(event) => setSelectedInterventionsNo(0)}
            />
             <div className={classes.ErrorMessage}>
                {error}
            </div>
            
        </>
    )
    return (
        <div className={classes.OfficeGeneralService}>
            {renderContent}
        </div>
    )
}