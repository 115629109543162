import React, {useState, useEffect, useContext} from 'react';
import {Table} from 'antd';
import classes from './Table.css';
import axios from 'axios';
import 'antd/dist/antd.css';

import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {OfferDataContext} from "../../Provider/OfferData";

const AdminTable = (props) => {
    const [offers, setOffers] = useState(null);
    const {isLoggedHook,setIsLoggedHook} = useContext(OfferDataContext);
    let history = useHistory();

    useEffect(()=>{
        if (isLoggedHook){
            history.push('/admin/oferte')
        }else{
            history.push('/login')
        }
    },[isLoggedHook])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/offers`)
            .then(res => {
                for (let item in res.data) {
                    res.data[item]['chosen_service'] = JSON.parse(res.data[item]['chosen_service'])
                    for (let value in res.data[item]['chosen_service'])
                        res.data[item]['chosen_service'][value] =
                            <li id={res.data[item].id}>{res.data[item]['chosen_service'][value]}</li>
                }
                setOffers(res.data)
            }).catch(err => {
        })
    }, [])

    const columns = [
        {
            title: 'NUME',
            width: 100,
            dataIndex: 'first_name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'PRENUME',
            width: 100,
            dataIndex: 'last_name',
            key: 'age',
        },
        {
            title: 'FIRMA',
            dataIndex: 'company',
            key: '1',
            width: 150,
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            key: '2',
            width: 150
        },
        {title: 'TELEFON', dataIndex: 'phone', key: '3', width: 110},

        {title: 'CUI', dataIndex: 'cui', key: '4', width: 120},
        {title: 'ORAS', dataIndex: 'city', key: '5', width: 100},
        {title: 'JUDET', dataIndex: 'region', key: '6', width: 100},
        {
            title: 'STATUS',
            key: 'is_checked',
            width: 100,
            render: (record, text) => <select onChange={(ev) => changeHandler(record.id, ev.target.value)}>
                <option selected={text['is_checked'] == 0} value={0}>nefacut</option>
                <option selected={text['is_checked'] == 1} value={1}>facut</option>
                <option selected={text['is_checked'] == 2} value={2}>in discutii</option>
            </select>,
            filters: [
                {
                    text: 'nefacut',
                    value: '0',
                },
                {
                    text: 'facut',
                    value: '1',
                },
                {
                    text: 'in discutii',
                    value: '2',
                },
            ],
            onFilter: (value, record) => value == record.is_checked
        },
        {
            title: 'SERVICII',
            dataIndex: 'chosen_service',
            key: '7',
            width: 250
        },
        {
            title: 'NR. AGENTI',
            dataIndex: 'persons_no',
            key: '8',
            width: 100,
            sorter: (a, b) => a.persons_no - b.persons_no,
        },
        {title: 'NR. ORE', dataIndex: 'hours_no', key: '9', width: 100},
        {title: 'NR. INTERV.', dataIndex: 'interventions_no', key: '10', width: 100},

        {title: 'PRET C.BIROU', dataIndex: 'oc_price', key: '11', width: 100},
        {title: 'PRET REDUS C.BIROU', dataIndex: 'oc_reduced_rice', key: '12', width: 100},

        {title: 'SUPRAFATA C.GENERALA', dataIndex: 'gc_surface', key: '13', width: 100},
        {title: 'PRET C.GENERALA', dataIndex: 'gc_price', key: '14', width: 100},

        {title: 'SUPRAFATA S.MOCHETA', dataIndex: 'cc_surface', key: '15', width: 100},
        {title: 'PRET S.MOCHETA', dataIndex: 'cc_price', key: '16', width: 100},

        {title: 'PRET ALT SERVICIU', dataIndex: 'other', key: '17', width: 100},
        {
            title: 'DATA',
            dataIndex: 'created_at',
            key: '18',
            width: 150,
            sorter: (a, b) => a.is_checked - b.is_checked,
        },
    ];
    const changeHandler = (key, value) => {
        const status = {status: value}
        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/offers/` + key, status)
            .then((res) => {
                axios.get(`${process.env.REACT_APP_SERVER_URL}/api/offers`)
                    .then(res => {
                        for (let item in res.data) {
                            res.data[item]['chosen_service'] = JSON.parse(res.data[item]['chosen_service'])
                            for (let value in res.data[item]['chosen_service'])
                                res.data[item]['chosen_service'][value] =
                                    <li id={res.data[item].id}>{res.data[item]['chosen_service'][value]}</li>
                        }
                        setOffers(res.data)
                    }).catch(err => {
                })
            })
            .catch((error) => {
                console.log(error);
            });
    };
    // let table = (isLoggedHook ? <Table columns={columns} bordered pagination={{defaultPageSize: 8}}
    //                                                     dataSource={offers} scroll={{x: 1300}}/> :
    //     <Redirect to="/login"/>);
    return (
        <div className={classes.Table}>
            <Table columns={columns} bordered pagination={{defaultPageSize: 8}}
                   dataSource={offers} scroll={{x: 1300}}/>
        </div>
    )
}
export default AdminTable;
