import React, {useState, useEffect, useContext} from "react";
import classes from './Login.css';
import axios from 'axios';
import {OfferDataContext} from '../../Provider/OfferData';
import {Redirect, useHistory} from "react-router-dom";

const Login = () => {
    const [loginData, setLoginData] = useState({user: '', password: ''});
    const [error, setError] = useState(false);
    const {isLoggedHook,setIsLoggedHook} = useContext(OfferDataContext);
    console.log(isLoggedHook)
    const userHandler = (value) => {
        setLoginData({
            ...loginData, user: value
        })
    }
    const passwordHandler = (value) => {
        setLoginData({...loginData, password: value})
    }
    useEffect(()=>{
        if (isLoggedHook){
            history.push('/admin/oferte')
        }
    },[isLoggedHook])
    useEffect(() => {
        setError(false);
    }, [loginData])
    let history = useHistory();
    const loginHandler = (event) => {
        event.preventDefault();
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/login`, loginData)
            .then((res) => {
                localStorage.setItem('token', res.data.token)
                setIsLoggedHook(true)
                history.push('/admin/oferte')
            })
            .catch((error) => {
                setError(true);
            });
    }

    const backToForm = (ev) => {
        ev.preventDefault();
        return <Redirect to="/"/>
    }
    let errorMessage = error ? <p style={{color: 'darkred'}}>User sau parola incorecta.</p> : null;
    return (
            <div>
                <form className={classes.Login}>
                    <h3 style={{color: 'black !important'}}>Admin Profile</h3>
                    {errorMessage}
                    <div className="form-group">
                        <label>Email address</label>
                        <input type="email" className="form-control" onChange={(ev) => userHandler(ev.target.value)}
                               placeholder="Enter email"/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control"
                               onChange={(ev) => passwordHandler(ev.target.value)} placeholder="Enter password"/>
                    </div>
                    <button onClick={(ev) => loginHandler(ev)} className="btn btn-primary btn-block">Submit</button>
                </form>
            </div>
    );
}
export default Login;