import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";


import classes from './Menu.css';

const menu = (props) => {

    let menu = props.showMenu ? 
    <FontAwesomeIcon className={classes.Menu} onClick={props.closeMenu} icon={faTimes}/>:
    <FontAwesomeIcon className={classes.Menu} onClick={props.openMenu} icon={faBars}/> 
    return(
        <>
        {menu}
        </>
    )
};
export default menu;