import React, {Component, useContext, useEffect} from 'react';
import classes from './App.css';
import Layouts from "./hoc/Layouts/Layouts";
import ContactData from "./containers/ContactData/ContactData";
import AdminTable from "./containers/Admin/Table";
import {OfferDataContext} from './Provider/OfferData';
import {OfferDataProvider} from './Provider/OfferData';
import Login from './containers/Login/Login'
// import { BrowserRouter as Router,Route} from "react-router-dom";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import {PageNotFound} from "./components/404/PageNotFound";
import Contracts from "./containers/Admin/Contracts";
import {ServiceForm} from "./containers/ContactData/ServiceForm/ServiceForm";

const App = () => {
    const context = useContext(OfferDataContext);
    const {isLoggedHook, setIsLoggedHook} = context;
    useEffect(async () => {
        console.log('each time')
        const token = await localStorage.getItem('token')
        if (token) {
            setIsLoggedHook(true)
        } else {
            setIsLoggedHook(false)
        }
    }, [])

    return (
        <Router>
            <div className={classes.App}>
                <Layouts>
                    <Switch>
                        <Route exact path='/admin/oferte'
                               render={() => <AdminTable/>}/>
                        <Route exact path='/' render={() => <ServiceForm/>}/>
                        {/*<Route exact path='/' render={() => <ServiceForm/>}/>*/}
                        <Route exact path='/login' component={Login}/>
                        <Route path='*' exact={true} component={PageNotFound}/>
                    </Switch>
                </Layouts>
            </div>
        </Router>
    );
}
export default App;
